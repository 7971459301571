import { UAParser } from 'ua-parser-js';

const uap = new UAParser();

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export function clone(input) {
  return JSON.parse(JSON.stringify(input));
}

export function useBrowser() {
  const isChrome = uap.getBrowser().name?.includes('Chrome') || false;
  const isFirefox = uap.getBrowser().name?.includes('Firefox') || false;
  const isSafari = uap.getBrowser().name?.includes('Safari') || false;

  return { isChrome, isFirefox, isSafari };
}