import { createI18n } from 'vue-i18n';
import { datetimeFormats, defaultLocale, messages } from '@/i18n/options';

export function appCreateI18n() {
  const currentLocale = window.location.pathname.substring(1, 3);

  return createI18n({
    messages,
    legacy: false,
    datetimeFormats,
    locale: currentLocale || defaultLocale,
    fallbackLocale: defaultLocale,
    missingWarn: false,
    fallbackWarn: false
  });
}

