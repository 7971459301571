import OpenLink from '@/modules/Overlands/openLink.js';

const openNewTab = {
  install(app, options) {
    // TODO: Mixin is not recommended solution according to Vue 3. Needs to find another work around in future
    app.mixin({
      mounted() {
        new OpenLink(options.className);
      }
    });
  }
};

export default openNewTab;
