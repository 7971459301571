import en from './en.json';
import ja from './ja.json';

export enum Locales {
  EN = 'en',
  JA = 'ja'
}

export const messages = {
  [Locales.EN]: en,
  [Locales.JA]: ja,
};

const setDateTimeFormats = {
  short: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
};

export const datetimeFormats  = {
  en: setDateTimeFormats,
  ja: setDateTimeFormats,
};

export const defaultLocale = Locales.EN;
